import useSafeState from '../../utils/safe-state';
import React, {FC, ReactElement, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, InputNumber, Space, Popconfirm} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {BackendFile} from "#/interfaces/BackendFile";
import {
    DownloadOutlined,
    WarningOutlined
} from "@ant-design/icons";

import { Image } from 'antd';
import {DeleteTwoTone} from "@ant-design/icons/lib";


interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const ClaimDrawer  = (props: Interface) => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const data :any = props.data;
    const [form] = Form.useForm();
        
    const [backendFiles, setBackendFiles] = useSafeState<Array<BackendFile>>([]);

    
    type ProductImage = {
        fileName: string,
        folder : string,
        id : number
    }

    useEffect(() => {

        getImages();
        
    },[])

    const getImages = () => {

        if(!axiosInstance.current){
            return;
        }

        axiosInstance.current.get('/resource/requestfile/get-filenames?requestId=' + props.data.requestId.id)
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {

                    let imgList: ProductImage[] = [];

                    response.data.forEach(value => {

                        imgList.push(
                            {
                                id: value.id,
                                folder: value.folder,
                                fileName: value.fileName
                            }
                        );
                    });
                    setBackendFiles(imgList);
                }
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });
    }

    useEffect(() => {

        setBackendFiles(data.documents);

    },[data])

    const IconText = ({ icon , text} : any) => (
        <div>
            {icon}
            {text}
        </div>
    );
    
    const generateFiles =  (msgDoc : BackendFile[]) => {

        let fileArray: ReactElement[] = [];

        if (msgDoc == null || msgDoc.length == 0) {

            fileArray.push(  <IconText
                icon={<WarningOutlined style={{marginRight:6}}/>}
                text={<Trans>Files not found to this claim</Trans>}
            />);

            return fileArray;
    }


    const handleDownloadFile = (value:any): void => {
        if (axiosInstance.current != null) {
            const FileDownload = require('js-file-download');
            axiosInstance
                .current({
                    url: process.env.REACT_APP_API_BASE_URL + '/public/file/get?folder=' + value.folder + '&filename=' + value.fileName,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(response => {
                    FileDownload(response.data, value.fileName);
                });
        }
    };

        const handleDeleteFile = (value:any): void => {
            if (axiosInstance.current != null) {
                axiosInstance
                    .current
                    .delete(
                        process.env.REACT_APP_API_BASE_URL + '/public/file/delete/request-document/' + value.id,
                        {withCredentials: true}
                    )
                    .then(response => {
                        close();
                    });
            }
        };

    const downloadIcon = (value:any) => (
        <>
            <DownloadOutlined
                className={"ActionButtons"}
                style={{color: "#40a9ff"}}
                onClick={() => handleDownloadFile(
                           value
                        )}
            />
            {value.fileName}
            <DeleteTwoTone
                className={"ActionButtons"}
                twoToneColor="red"
                style={{color: "red"}}
                onClick={() => handleDeleteFile(
                    value
                )}
            />
        </>
    ); 

        msgDoc.forEach((value) => {

            fileArray.push(
                <span style={{maxHeight: 300, paddingRight: "25px", overflow: "hidden"}}>
                    <div style={{marginBottom: "10px", display: "inline-block"}}>
                        <>
                        {downloadIcon(value)}
                        </>
                    </div>
                </span>);

        });

        return fileArray;
    }

    return(
        <Form id={props.mode}  form={form} layout="vertical" >
            {
                <>
                    <Row style={{marginBottom:10}} gutter={24}>
                        <Col span={24}>
                            {generateFiles(backendFiles)}
                        </Col>
                    </Row>
                </>
            }
        </Form>
    );

}
export default ClaimDrawer;
