import React, {FC} from "react";
import TestForm from "../../forms/claim-handling-forms/test-form";

interface Interface{
    requestId?: any;
    mode?: "View" | "AddNew";
}
const TestScreen: FC<Interface> = (props:any) => {

    return (

        <TestForm
            requestId={props.requestId}
            mode={props.mode}
        />

    );
}
export default TestScreen;
