import React, {useEffect} from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import ClaimListModel from "../../models/dto/claim-list-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, message, Popconfirm, Tooltip, Button, Modal, Spin, InputNumber} from "antd";
import {Trans} from "@lingui/macro";
import diagram from "../../images/claim_handling_diagram.png"

import {
    EyeOutlined,
    ForwardOutlined,
    FileOutlined,
    InfoCircleOutlined,
    EditTwoTone,
    CloseCircleOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    LoadingOutlined, PartitionOutlined
} from "@ant-design/icons/lib";

import isAuthorized from "../../utils/is-authorized";
import {useKeycloak} from "@react-keycloak/web";
import {PlusOutlined} from "@ant-design/icons/lib";
import Drawer from "../../components/drawer/drawer";
import ClaimDrawer from "../../forms/claim-handling-forms/claim-drawer";
import CorrectionScreen from "../claim-handling/correction";
import RectificationScreen from '../claim-handling/rectification';
import ControlAcceptorScreen from '../claim-handling/control-acceptor';
import ApprovalScreen from '../claim-handling/approval';
import AcceptScreen from '../claim-handling/accept';
import CheckScreen from '../claim-handling/check';
import ApprovalCloseScreen from '../claim-handling/approval-close';
import ErrorReasonScreen from '../claim-handling/error-reason';
import ReleaseScreen from "../claim-handling/release";
import ControlAcceptorToErrorReasonScreen from "../claim-handling/control-acceptor-to-error-reason";
import ApprovalToErrorReasonScreen from "../claim-handling/approval-to-error-reason";
import AcceptToImplementScreen from "../claim-handling/accept-to-implement";
import CheckToImplementScreen from "../claim-handling/check-to-implement";
import ApprovalToImplementScreen from "../claim-handling/approval-to-implement";
import ErrorExistsFirstScreen from "../claim-handling/error-exists-first";
import ErrorExistsSecondScreen from "../claim-handling/error-exists-second";
import ErrorExistsThirdScreen from "../claim-handling/error-exists-third";
import {AxiosResponse} from "axios";
import {openNotification} from "../../utils/openNotification";
import ReactJson from "react-json-view";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import { Image } from 'antd';
import TestScreen from "../../screens/claim-handling/test-screen";



const ClaimListScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();
    const [trigger, setTrigger] = useSafeState(false);

    
    const [currentScreen, setCurrentScreen] = useSafeState<any>('main');
    const [requestId, setRequestId] = useSafeState<any>();
    const [screenMode, setScreenMode] = useSafeState<any>();
    const [partSource, setPartSource] = useSafeState<any>();
    const [isInfoLoading, setIsInfoLoading] = useSafeState(false);
    const [xlsDownloading, setXlsDownloading] = useSafeState(false);

    const [isModalVisible, setIsModalVisible] = useSafeState(false);
    const [actQty, setActQty] = useSafeState(0);
    const [actRequestId, setActRequestId] = useSafeState(0);

    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const text = <Trans>Claim handling uploaded files list</Trans>

    const drawer = Drawer(
        {
            title: text,
            children:
                <ClaimDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    useEffect(() => {
        setTrigger(prevState => !prevState);
    }, []);


    const swScreen = (record:any) => {

        if(mtskAxiosInstance.current != null){

            mtskAxiosInstance.current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/requests/i-am-the-next?requestId=' + record.id)
                .then(function (response: AxiosResponse<any>) {

                    if(response.data == true){

                        let currStr = 'main';
                        setRequestId(record.id);
                        setPartSource(record.partSource);

                        switch (record.currentStateId) {
                            case 28:
                                currStr = 'correction';
                                break;
                            case 29:
                                currStr = 'rectification';
                                break;
                            case 30:
                                currStr = 'errorReason';
                                break;
                            case 31:
                                currStr = 'controlAcceptor';
                                break;
                            case 33:
                                currStr = 'approval';
                                break;
                            case 34:
                                currStr = 'accept';
                                break;
                            case 35:
                                currStr = 'check';
                                break;
                            case 36:
                                currStr = 'approvalClose';
                                break;
                            case 38:
                                currStr = 'errorExistsFirst';
                                break;
                            case 39:
                                currStr = 'errorExistsSecond';
                                break;
                            case 40:
                                currStr = 'errorExistsThird';
                                break;


                            case 17:
                                currStr = 'controlAcceptorToErrorReason';
                                break;
                            case 19:
                                currStr = 'approvalToErrorReason';
                                break;
                            case 21:
                                currStr = 'acceptToImplement';
                                break;
                            case 23:
                                currStr = 'checkToImplement';
                                break;
                            default:
                                message.error(antdContext('A folyamat már csak lezárásra vár!'));
                                break;
                        }

                        setCurrentScreen(currStr);
                        setScreenMode('AddNew');

                    } else {
                        message.error(antdContext('Nincs jogosultságod folytatni!'));
                    }
                })
                .catch(function (error :any) {
                    message.error(antdContext('Nincs jogosultságod folytatni!'));
                });
        }
    }

    const swMainScreen = (record:any) => {

        let currStr = 'main';
        setRequestId(record.id);
        setPartSource(record.partSource);

        currStr = 'test';

        setCurrentScreen(currStr);
        setScreenMode('Edit');

    }

    const closeClaim = (record:any) => {

        if(mtskAxiosInstance.current != null){

            mtskAxiosInstance.current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/requests/i-am-the-next?requestId=' + record.id)
                .then(function (response: AxiosResponse<any>) {

                    if(response.data == true){

                        if(mtskAxiosInstance.current != null) {

                            mtskAxiosInstance.current.post("/resource/requests/complete-by-name-and-action-type?requestId=" + record.id + "&stateName=" + "Closing" + "&actionType=APPROVE",
                                {}
                            ).then(() => {

                                message.success(antdContext('Sikeres lezárás'));
                                setTrigger(false);
                                setTrigger(!trigger);

                            }).catch(function (error :any) {
                                message.error(antdContext('Sikertelen lezárás!')).then(r => {console.log(r)});
                                console.log(error)
                            });
                        }

                    } else {
                        message.error(antdContext('Nincs jogosultságod lezárni!'));
                    }
                })
                .catch(function (error :any) {
                    message.error(antdContext('Nincs jogosultságod lezárni!'));
                });
        }
    }

    const getViewScreen = (record:any) => {
        let currStr = 'test';
        setRequestId(record.id);

        setCurrentScreen(currStr);
        setScreenMode(record.actionId == 2 ? 'Edit' : 'View');
    }

    const swButton = (record: any) => {
        return (
            <>
                <Tooltip title={<Trans>Continue</Trans>}>
                    <ForwardOutlined onClick={()=>swScreen(record)} className={"ActionButtons"} style={{color: "#40a9ff"}} />
                </Tooltip>
            </>
        )
    }

    const action =  (isMTSK: Boolean, record: any) => {

            return (            

            <>
                {/*{swButton(record)}*/}

                <Tooltip title={<Trans>Continue</Trans>}>
                    <ForwardOutlined onClick={()=>swMainScreen(record)} className={"ActionButtons"} style={{color: "#40a9ff"}} />
                </Tooltip>

                <Tooltip  title={<Trans>Informations</Trans>}>
                    <InfoCircleOutlined onClick={() => getInfo(record.id)} className={"ActionButtons"} style={{color: "#40a9ff"}}/>
                </Tooltip>

                <Tooltip placement="top" title={<Trans>Add Finally Wrong Qty</Trans>}>

                    <EditTwoTone
                        disabled={record.currentStateId < 42 ? false : true}
                        twoToneColor={record.currentStateId < 42 ? "#40a9ff" : "gray"}
                        onClick={() => record.currentStateId < 42 ? showModal(record.id) : {} }
                        className={"ActionButtons"}
                        style={{color: record.currentStateId < 42 ? "#40a9ff" : "gray" }}
                    />
                </Tooltip>

                <Tooltip title={<Trans>Download files</Trans>}>
                    <FileOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>drawer.open("", {requestId: record}) }/>
                </Tooltip>

                <Tooltip title={<Trans>Close</Trans>}>
                    <CloseCircleOutlined  className={"ActionButtons"} style={{color: "#f5222d"}} onClick={ () => closeClaim(record)}/>
                </Tooltip>

                <Tooltip title={<Trans>Excel export</Trans>}>
                    <FileExcelOutlined className={"ActionButtons"} style={{color: "#40a9ff"}} spin={xlsDownloading} onClick={ () => getXls(record.id)}/>
                </Tooltip>


            </>
    )};

    const actions = {
        width: 200,
        render: (text: string, record: any) => action(isMTSK(), record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const isMTSK = () => {
        return !isAuthorized(keycloak,["MTSK_SUPPL_USER", "MTSK_SUPPL_ADMIN"]);
    }

    const getInfo = (id: number) => {

        if(mtskAxiosInstance.current != null){
            setIsInfoLoading(true);

            mtskAxiosInstance.current.get(process.env.REACT_APP_API_BASE_URL + '/resource/request-data/get-request-data-view?requestId=' + id)
                .then(function (response: AxiosResponse<any>) {
                    Modal.info({
                        closable: true,
                        okText: 'Bezárás',
                        width: 875,
                        title: 'Reklamáció adatai',
                        content: (
                            <ReactJson
                                name={false}
                                collapsed={1}
                                indentWidth={8}
                                displayDataTypes={false}
                                displayObjectSize={false}
                                src={response.data}
                                enableClipboard={false}
                                quotesOnKeys={false}

                                //theme={'monokai'}

                                style={{
                                    padding: 2.5,
                                    width: 800,
                                    height: 800,
                                    overflowY: "scroll",
                                    color: "black",
                                    fontSize: "larger",
                                    fontFamily: "system-ui"
                                }}
                            />
                        ),
                        onOk() {},
                    });
                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a mentés közben'));
                    setTrigger(false);
                    setTrigger(!trigger);
                    console.log(error);
                }).finally(() => {
                    setIsInfoLoading(false);
                });
        }
    };

    const getWorkflowImg = () => {

        Modal.info({
            closable: true,
            okText: 'Bezárás',
            width: 350,
            title: 'Reklamáció folyamata',
            content: (
                <Image
                    height={400}
                    src={diagram}
                    alt={'Nincs megjeleníthető kép'}
                />
            ),
            onOk() {},
        });
    };


    const getXls = (id: number) => {

        if(mtskAxiosInstance.current != null){

            setXlsDownloading(true);

            const FileDownload = require('js-file-download');

            mtskAxiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/request-data/xls?requestId=' + id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, 'ClaimHandling_' + id + '.xls');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setXlsDownloading(false));
        }
    }

    const showModal = (id: any) => {
        setIsModalVisible(true);
        setActQty(0);
        setActRequestId(id);
    };

    const handleOk = () => {
        setIsModalVisible(false);

        if(mtskAxiosInstance.current != null) {

            mtskAxiosInstance.current.post("/resource/request-data/add-data-by-request-id?requestId=" + actRequestId, { initData: {qtyOfFinallyWrong: actQty}}
            ).then(() => {
                message.success(antdContext('Sikeresen rögzítve'));
                setTrigger(false);
                setTrigger(!trigger);

            }).catch(function (error :any) {
                message.error(antdContext('Sikertelen rögzítés!')).then(r => {console.log(r)});
                console.log(error)
            });
        }

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (<>
    {currentScreen == 'main' ? (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Claim list'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={
                    isMTSK()
                        ? (
                            <>
                                <Button type={"primary"} onClick={ () => setCurrentScreen('test')} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new claim</Trans></span></Button>
                                <Button onClick={ () => getWorkflowImg()} style={{float: "left", marginRight:10}}><PartitionOutlined /></Button>
                            </>
                        )
                        :(
                            <>
                                <Button onClick={ () => getWorkflowImg()} style={{float: "left", marginRight:10}}><PartitionOutlined /></Button>
                            </>
                        )
                }
                allowRefreshButton={true}
                model={ClaimListModel}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
            <Modal title={<Trans>Add Finally Wrong Qty</Trans>} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={300}>
                    <InputNumber value={actQty}
                                 onInput={
                                     (value) => {
                                         setActQty(Number(value));
                                     }
                                 }
                                 onStep={
                                     (value) => {
                                         setActQty(value);
                                     }
                                 }
                                 style={{width: "100%"}}
                                 min={0}
                    />

                </Modal>

                {drawer.component}
                </>
        ) :
        (

        currentScreen == 'release' ? (
        <ReleaseScreen requestId = {requestId} mode={screenMode}/>) : (
        currentScreen == 'correction' ? (
        <CorrectionScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'rectification' ? (
        <RectificationScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'controlAcceptor' ? (
        <ControlAcceptorScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'approval' ? (
        <ApprovalScreen requestId = {requestId} mode={screenMode} partSource={partSource} />) : (
        currentScreen == 'accept' ? (
        <AcceptScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'check' ? (
        <CheckScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'approvalClose' ? (
        <ApprovalCloseScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'errorReason' ? (
        <ErrorReasonScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'release' ? (
        <ReleaseScreen requestId = {requestId} mode = {screenMode} />) : (
        currentScreen == 'controlAcceptorToErrorReason' ? (
        <ControlAcceptorToErrorReasonScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'approvalToErrorReason' ? (
        <ApprovalToErrorReasonScreen requestId = {requestId} mode={screenMode} partSource={partSource}/>) : (
        currentScreen == 'acceptToImplement' ? (
        <AcceptToImplementScreen requestId = {requestId} mode = {screenMode}  partSource={partSource}/>) : (
        currentScreen == 'checkToImplement' ? (
        <CheckToImplementScreen requestId = {requestId} mode = {screenMode} partSource={partSource}/>) : (
        currentScreen == 'approvalToImplement' ? (
        <ApprovalToImplementScreen requestId = {requestId} mode = {screenMode} partSource={partSource}/>) : (
        currentScreen == 'errorExistsFirst' ? (
        <ErrorExistsFirstScreen requestId = {requestId} mode = {screenMode} partSource={partSource}/>) : (
        currentScreen == 'errorExistsSecond' ? (
        <ErrorExistsSecondScreen requestId = {requestId} mode = {screenMode} partSource={partSource}/>) : (
        currentScreen == 'errorExistsThird' ? (
        <ErrorExistsThirdScreen requestId = {requestId} mode = {screenMode} partSource={partSource} />) : (currentScreen == 'test' ? <TestScreen requestId = {requestId} mode = {screenMode} ></TestScreen> : <></> )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
    }
    </>)
}
export default ClaimListScreen;
